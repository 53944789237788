/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "I built the Best Life Rewarded marketing site on WordPress with minimal plugins and javascript used. I worked with a designer to create this clean four-template site with subtle element transitions and animations. The site uses custom post types and form plugins to give the users a lot of customization while still conforming to the templates."), "\n", React.createElement(_components.p, null, "This site wasn’t overly challenging, but I had a lot of fun adding all the animations. For example, elements are animating when a user scrolls down the pages, and the navigation transitions out when scrolling down the page and then back in when you start scrolling up. This logic was written from scratch to keep the total bundle size as small as possible."), "\n", React.createElement(_components.p, null, "The site was bundled into a docker image for easy development and deployment. It was my first time deploying a WordPress site with Docker, and I recommend at least developing WordPress sites this way. I have had nightmares about setting up a local PHP environment on WAMP and MAMP. Using the Docker image made this a breeze."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
